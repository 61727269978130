import { Component, OnInit, Inject, ChangeDetectorRef, ViewRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { DndUploadService } from "../../_shared/dnd-upload.service";
import { CompressorService } from "../../_shared/compressor.service";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import {PhotoService} from '../../_shared/photo.service'
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../_shared/confirmation-dialog/confirmation-dialog.component';
import { map, expand } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css']
})
export class ImageDialogComponent implements OnInit {

  fileArr = [];
  imgArr = [];
  fileObj = [];
  form: FormGroup;
  msg: string;
  progress: number = 0;

  imgList=[];
  compressedImages: File[] =[];

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public holonumber: string,
    public fb: FormBuilder,
    private sanitizer: DomSanitizer,
    public dragdropService: DndUploadService,
    public ps: PhotoService,
    public dialog:MatDialog,
    private compressor: CompressorService,
    private ref: ChangeDetectorRef
    ){
      this.form = this.fb.group({
        avatar: [null]
      })

      setInterval(() => {
        if (this.ref !== null && this.ref !== undefined && !(this.ref as ViewRef).destroyed){
          this.ref.detectChanges();
        }
      }, 250);
    }

    ngOnInit(): void {
      this.loadPhotos();
      
    }
  
     loadPhotos(){
      this.imgList = [];
      //load existing images to grid.
      this.ps
      .getPhotos(this.holonumber)
      .subscribe((data)=>{
        console.log(data);
        console.log('set image list');
        this.imgList = data;
      });
    }

    upload(e) {
      const fileListAsArray = Array.from(e);
      // this.fileArr = [];
      // this.imgArr = [];
      // this.fileObj = [];
      // this.form = this.fb.group({
      //   avatar: [null]
      // })
      this.compressedImages =[];

      fileListAsArray.forEach((item, i) => {
        const file = (e as HTMLInputElement);
        const url = URL.createObjectURL(file[i]);
        this.imgArr.push(url);
        this.fileArr.push({ item, url: url });
      })
  
      this.fileArr.forEach((item) => {
        this.fileObj.push(item.item)
      })
  
      // Set files form control
      this.form.patchValue({
        avatar: this.fileObj
      })
  
      this.form.get('avatar').updateValueAndValidity()
      const compress = this.recursiveCompress( this.form.value.avatar[0], 0, this.form.value.avatar ).pipe(
        expand(res => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress( this.form.value.avatar[res.index], res.index, this.form.value.avatar );
        }),
      );
      compress.subscribe(res => {
        if (res.index > res.array.length - 1) {
        
        //  complete Upload to server
        var func = this.dragdropService.addFiles(this.compressedImages, this.holonumber)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              console.log(`Uploaded! ${this.progress}%`);
              break;
            case HttpEventType.Response:
              console.log('File uploaded successfully!', event.body);
              res.array.lenth = 0;
              setTimeout(()=>{
                this.progress = 0;
                this.fileArr = [];
                this.fileObj = [];
                //this.msg = "File uploaded successfully!"
                this.loadPhotos();
              }, 1000);
          }
        })
        }
      });
      }
  

    recursiveCompress = (image: File, index, array) => {
      return this.compressor.compress(image).pipe (
        map(response => {
  
        //Code block after completing each compression
          console.log('compressed ' + index + image.name);
          this.compressedImages.push(response);
          return {
            data: response,
            index: index + 1,
            array: array,
          };
        }),
      );
    }

    // Clean Url
    sanitize(url: string) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getPhotoUrl(photo)
    {
      return "url('http://tristarauthentic.com/uploads/"+ photo.HoloNumber + "/" + photo.HoloNumber + "_" + photo.Guid + photo.extension + "')";
    }

   

    deleteImage(imageID): void {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Are you sure you want to delete this Image?"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.ps
          .deletePhoto(imageID)
          .subscribe((data)=>{
            this.loadPhotos();
          });
         }
       });
    }

    

}
