import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import { ChartComponent } from 'angular2-chartjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})


export class DashboardComponent implements OnInit {

  @ViewChild(ChartComponent) chart: ChartComponent; 

  mostPopularColumns: string[] = ['Total', 'HoloNumber'];
  mostPopularDatasource= new MatTableDataSource()

  topCollectionColumns: string[] = ['Total', 'FullName'];
  topCollectionDatasource= new MatTableDataSource()

  topAthleteColumns: string[] = ['Total', 'Athlete'];
  topAthleteDatasource= new MatTableDataSource()

  SearchesTodayLoading:boolean = true;
  SearchesToday:number = 0;
  totalSearchesLoading:boolean = true;
  TotalSearches:number =0;
  TotalCollections:number =0;

  VisitorsTodayLoading:boolean = true;
  VisitorsToday:number=0;

  type = 'line';
  data = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: 'rgba(63,81,181,.3)',
    }
  ]
  };
  options = {
  responsive: true,
  maintainAspectRatio: false,
  legend:{
    display:false,
  }
  };

  constructor(private ds: DashboardService, private router: Router ) { }

  ngOnInit(): void {
    this.ds
    .getMostPopular()
    .subscribe((data)=>{
      //console.log(data);
      this.mostPopularDatasource = new MatTableDataSource(data);
    });

    this.ds
    .getTopAthlete()
    .subscribe((data)=>{
      //console.log(data);
      this.topAthleteDatasource = new MatTableDataSource(data);
    });

    this.ds
    .getSearchCountToday()
    .subscribe((data)=>{
      //console.log(data);
      this.SearchesToday = data;
      this.SearchesTodayLoading = false;
    });
    this.ds
    .getVisitorCountToday()
    .subscribe((data)=>{
      //console.log(data);
      this.VisitorsToday = data;
      this.VisitorsTodayLoading = false;
    });

    this.ds
    .getTotalSearches()
    .subscribe((data)=>{
      //console.log(data);
      this.TotalSearches = data;
      this.totalSearchesLoading = false;
    });

    this.ds
    .getTotalCollections()
    .subscribe((data)=>{
      //console.log(data);
      this.TotalCollections = data;
    });
      
    this.ds
    .getTopCollections()
    .subscribe((data)=>{
      //console.log(data);
      this.topCollectionDatasource = new MatTableDataSource(data);
    });
      
    this.ds
    .getDaySearch()
    .subscribe((data)=>{
      //console.log(data);
      for (let d of data) {
        this.data.labels.push(d['Month'] + "-" + d["Day"]);
        this.data.datasets[0].data.push(d["Visits"])
     }
    // console.log(this.data);
     this.chart.chart.update();
    });
  }

}
