import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CompressorService {

  constructor() { }
  compress(file: File): Observable<any> {
    var width = 1024; // For scaling relative to width
    var height = 1024; // For scaling relative to width

    const reader = new FileReader();
    reader.readAsDataURL(file);
    return Observable.create(observer => {
      reader.onload = ev => {
        const img = new Image();
        img.src = (ev.target as any).result;
        
        (img.onload = () => {
          
        const origWidth = img.width;
        const origHeight = img.height;
        var scaleFactor = 1;
        
        var orientation:string = "l";
        if(origHeight > origWidth){
          orientation = "p"
        }
        console.log('orig: ' + origWidth +  'x' + origHeight);
        console.log(orientation);

        if (orientation == "l" && origWidth > 1024){
            width = 1024;
            scaleFactor = width / origWidth;
            height = origHeight * scaleFactor;
        }

        if (orientation == "p" && origHeight > 1024){
          height = 1024;
          scaleFactor = height / origHeight;
          width = origWidth * scaleFactor;
        }

      console.log(width +  'x' + height);
      console.log(scaleFactor);

          const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
          elem.width = width;
          elem.height = height;
          const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob(
            blob => {
              observer.next(
                new File([blob], file.name, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                }),
              );
            },
            'image/jpeg',
            1,
          );
        }),
          (reader.onerror = error => observer.error(error));
      };
    });
  }
}