import { Component, OnInit } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

import { LoginService } from '../login/login.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError  } from '@angular/router';
import { GlobalProvider } from "../globals";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isLoading: true;
  isSaving:boolean = false;

  constructor(private ls: LoginService, private snackBar: MatSnackBar, private router: Router, public global: GlobalProvider) { }

  ngOnInit(): void {
  }


  onClickSubmit(formData) {
    this.isSaving = true;

    this.ls
      .adminAuth(formData)
      .subscribe((data)=>{
       // console.log(data)
        
        if (data["isError"] == true){
          //show error message
          let snackBarRef = this.snackBar.open(data["Message"], '' ,{duration: 1000});
        }else{
         // console.log(data["Data"]["AuthToken"])
            //set local storate
            localStorage.setItem("tristarAuthSession", data["Data"]["AuthToken"]);
            localStorage.setItem("tristarUserName", data["Data"]["Fullname"]);
            this.global.isAuthenticated = true;
            this.global.UserName = data["Data"]["Fullname"];
            window.location.href = '/dashboard';
            //this.router.navigate(['/dashboard']);
        }
        
        this.isSaving = false;
      });
  }

}
