import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HologramsService } from '../holograms/holograms.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subject, Observable, forkJoin } from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/internal/operators";
import { NgModel } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { GlobalProvider } from "../globals";
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../_shared/confirmation-dialog/confirmation-dialog.component';
import { ImageDialogComponent } from '../_shared/image-dialog/image-dialog.component';
import { Router } from '@angular/router';

import { EditListService } from "../holograms/edit-list.service";

@Component({
  selector: 'app-holograms',
  templateUrl: './holograms.component.html',
  styleUrls: ['./holograms.component.css']
})
export class HologramsComponent implements OnInit {

  displayedColumns: string[] = ['select','HoloImage', 'HoloNumber', 'Athlete', 'Team', 'Item',  'CityAuth', 'StateAuth', 'DateAuth', 'AuthShow', 'Inscription', 'Misc', 'TspMfg'];
  dataSource= new MatTableDataSource()
  dataLoading:boolean = true;
  isEditing:boolean = false;

  total_count = 0;
  search_count = 0;
  resultsLength = 0;
  
  filter: any = {};
  search: any = {};

  showBackdrop:boolean = false;
  searchUpdated : Subject<NgModel>= new Subject();

  editHolo: any={};

  editSelection: any=[];
  selList: any=[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('holoDrawer') sidenav: MatSidenav;

  constructor(private hs: HologramsService, public dialog:MatDialog, public global: GlobalProvider, private snackBar:MatSnackBar, private router:Router, private es: EditListService) {}


  ngOnInit(): void {
    this.es.currentMessage.subscribe(selList => this.selList = selList)

    this.loadHolograms()
    this.searchUpdated.pipe(debounceTime(500)).subscribe(value =>{
      this.loadHolograms();
     });
  }

  getPhotoUrl(photo)
  {
    return "url('"+ photo + "')";
  }

  toggleSelection(ev, item):void{
    //console.log(ev);
    //console.log(item);

    if(ev["checked"]==false){
      //remove item
      const index: number = this.editSelection.indexOf(item);
      if (index !== -1) {
          this.editSelection.splice(index, 1);
      } 
    }else{
      item.isSelected = true;
      this.editSelection.push(item);
      this.es.updateEditList(this.editSelection);
    }
  }

  doubleClick(row): void{
    this.clearSelections()
    console.log(row);
    this.showBackdrop = true;
    this.sidenav.open();  
    this.global.modalOpen = true;
    this.isEditing = true;
    this.editHolo=  Object.assign({}, row);

  }

  closeEdit(): void{
    this.showBackdrop = false;
    this.sidenav.close();
    this.global.modalOpen = false;
  }

  clearSelections():void{
      this.editSelection = [];
      this.dataSource.data.forEach(row => row["isSelected"] = false)
  }

  clearFilters(): void{
    //console.log('clear filters');
    this.paginator.pageIndex = 0;
    this.filter = {};
    this.search.filters = {};
    this.loadHolograms();
  }

  loadHolograms(): void{
    this.clearSelections();
    this.dataLoading = true;
    if (this.search.pageSize == null){
      this.search.pageSize = 50;
    }

    this.hs
    .getHolograms(this.search)
    .subscribe((data)=>{
     // console.log(data);
      this.dataSource = new MatTableDataSource(data["items"]);
      this.resultsLength = data["search_count_limited"];
      this.search_count = data["search_count"];
      this.total_count = data["total_count"];

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataLoading = false;
    })

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    this.paginator.pageIndex = 0;
    this.search.filters = this.filter;
    this.search.page = this.paginator.pageIndex + 1;
    //const filterValue = (event.target as HTMLInputElement).value;
    //console.log(this.search);
    this.searchUpdated.next(this.search)
  }

  pageChanged(event: Event){
    this.search.page = event["pageIndex"] + 1
    this.search.pageSize = event["pageSize"]
    //console.log(this.search);
    //this.searchUpdated.next(this.search)

  }

  updateHolo(): void{
    this.dataLoading = true;
    //console.log(this.editHolo);
    if (this.isEditing == true){
      this.hs
        .updateHolo(this.editHolo)
        .subscribe((data)=>{
          //console.log('saved');
          let snackBarRef = this.snackBar.open('Hologram Updated', '' ,{duration: 1000});
          this.loadHolograms();
          this.sidenav.toggle();
          this.showBackdrop = false;
          this.global.modalOpen = false;
      })
    }else{
      this.hs
        .newHolo(this.editHolo)
        .subscribe((data)=>{
          //console.log('saved');
          let snackBarRef = this.snackBar.open('New Hologram Created', '' ,{duration: 1000});
          this.loadHolograms();
          this.sidenav.toggle();
          this.showBackdrop = false;
          this.global.modalOpen = false;
      })
    }
    
  }

  editBulk():void{
    // console.log(this.editSelection);
    // this.hs.updateBulkEditList(this.editSelection);
    // setTimeout(()=>{
    //   this.router.navigateByUrl('/holograms/editor');
    // },1000);
    
    this.router.navigateByUrl('/holograms/editor');
  }

  cloneBulk():void{
      //duplicate all of the selected records
      let q=[];

      this.editSelection.forEach((item)=>{
        let editItem=  Object.assign({}, item);
        editItem["HoloNumber"] = editItem["HoloNumber"] + "_copy";
        q.push(this.hs.newHolo(editItem));
      })

      forkJoin(q)
       .subscribe((response) => {
         // console.log(response);
          let snackBarRef = this.snackBar.open('Seelcted Holograms Cloned', '' ,{duration: 1000});
          this.loadHolograms();
          this.clearSelections();
       });
  }


  deleteBulk():void{
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: "Are you sure you want to delete the selected Holograms?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let q=[];

        this.editSelection.forEach((item)=>{
          q.push(this.hs.deleteHolo(item.ID));
        })

        forkJoin(q)
        .subscribe((response) => {
           // console.log(response);
            let snackBarRef = this.snackBar.open('Selected Holograms Deleted', '' ,{duration: 1000});
            this.loadHolograms();
            this.clearSelections();
        });
          }
    });

    
  }

  deleteHolo(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: "Are you sure you want to delete this Hologram?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //yes clicked - delete
        this.dataLoading = false;
        this.hs
        .deleteHolo(this.editHolo.ID)
        .subscribe((data)=>{
          //console.log('saved');
          let snackBarRef = this.snackBar.open('Hologram Deleted', '' ,{duration: 1000});
          this.loadHolograms();
          this.sidenav.toggle();
          this.showBackdrop = false;
          this.global.modalOpen = false;
      })
      }
    });
  }

  editImages(holoID):void{
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: '50%',
      maxHeight: '90vh',
      data: holoID
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadHolograms();
    });
  }
  

}


