import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from "../globals";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient,  public global: GlobalProvider) { }
  msgHeader:HttpHeaders =  new HttpHeaders({'X-Api-Auth-Token':  `${this.global.tristarApiKey}`, 'Content-Type':'application/json'});

  getSearchLog(search) {
    return this
          .http.post<searchLogAPI[]>(`${this.global.apiBaseUrl}` + 'search/searchLog', search, {
            headers: this.msgHeader})
  }

}

export interface searchLogAPI {
  SearchDate: Date;
  CustomerID: number;
  HoloNumber: string;
  FirstName: string;
  LastName: string
  IPaddress: string;
}
