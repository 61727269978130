import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from "../globals";
import {MostPopularSearch} from "../_models/most-popular-search"
import {TopCollection} from "../_models/top-collection"
import { DaySearch } from '../_models/day-search';
import { TopAthlete } from '../_models/top-athlete';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {



  constructor(private http: HttpClient,  public global: GlobalProvider) { }

  msgHeader:HttpHeaders =  new HttpHeaders({'X-Api-Auth-Token':  `${this.global.tristarApiKey}`, 'Content-Type':'application/json'});

  getMostPopular() {
    return this
          .http.get<MostPopularSearch[]>(`${this.global.apiBaseUrl}` + 'search/mostPopularSearch', {
            headers: this.msgHeader})
  }
  getTopAthlete() {
    return this
          .http.get<TopAthlete[]>(`${this.global.apiBaseUrl}` + 'search/getTopAthlete', {
            headers: this.msgHeader})
  }
  getTopCollections() {
    return this
          .http.get<TopCollection[]>(`${this.global.apiBaseUrl}` + 'search/topCollections', {
            headers: this.msgHeader})
  }
  getSearchCountToday() {
    return this
          .http.get<number>(`${this.global.apiBaseUrl}` + 'search/searchCountToday', {
            headers: this.msgHeader})
  }
  getVisitorCountToday() {
    return this
          .http.get<number>(`${this.global.apiBaseUrl}` + 'search/visitorCountToday', {
            headers: this.msgHeader})
  }
  getTotalSearches() {
    return this
          .http.get<number>(`${this.global.apiBaseUrl}` + 'search/totalSearches', {
            headers: this.msgHeader})
  }
  getTotalCollections() {
    return this
          .http.get<number>(`${this.global.apiBaseUrl}` + 'search/totalCollections', {
            headers: this.msgHeader})
  }

  getDaySearch() {
    return this
          .http.get<DaySearch[]>(`${this.global.apiBaseUrl}` + 'search/searchesByDay', {
            headers: this.msgHeader})
  }
}
