import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private http: HttpClient,  public global: GlobalProvider) { }
  msgHeader:HttpHeaders =  new HttpHeaders({'X-Api-Auth-Token':  `${this.global.tristarApiKey}`, 'Content-Type':'application/json'});


  getAlertSettings() {
    return this
          .http.get<settingsVM[]>(`${this.global.apiBaseUrl}` + 'alerts/getSettings', {
            headers: this.msgHeader})
  }

  saveAlertSettings(alert: settingsVM) {
    return this
          .http.post<settingsVM[]>(`${this.global.apiBaseUrl}` + 'alerts/saveSettings', alert, {
            headers: this.msgHeader})
  }

}

export interface settingsVM {
  email: string;
  maxSearches: number;
  volumeEmail: string;
  volumeSearches: number;
}
