import { Component, ViewChild, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { LoginService } from './login/login.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError  } from '@angular/router';
import { GlobalProvider } from "./globals";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tristarAdmin';
  opened = true;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(private ls: LoginService, private router: Router, public global: GlobalProvider) {}

  ngOnInit() {
   // console.log(window.innerWidth)
    if (window.innerWidth < 768) {
      this.sidenav.fixedTopGap = 55;
      this.opened = false;
    } else {
      this.sidenav.fixedTopGap = 65;
      this.opened = true;
    }

    //check to see if user is auhenticated on load. if not go to the login page
    var UserName = localStorage.getItem("tristarUserName");
    var AuthSession = localStorage.getItem("tristarAuthSession");


    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
          // Hide loading indicator
          //check autentication each time the route has changed
          if (AuthSession != null){
            //check to see if the auth session is active.
            this.ls.isActive(AuthSession)
              .subscribe((data)=>{
                //console.log(data)
                if (data['isError'] == true){
                  this.router.navigate(['/login']);
                  this.opened = false;
                }else{
                  this.global.isAuthenticated = true;
                  this.global.UserName = data["Data"]["Fullname"];
                  this.opened = true;
                }
              });
          }else{
            this.router.navigate(['/login']);
            this.opened = false;
          }
    
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator
          // Present error to user
          console.log(event.error);
      }
  });

  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.sidenav.fixedTopGap = 55;
      this.opened = false;
    } else {
      this.sidenav.fixedTopGap = 65;
      if (this.global.isAuthenticated ==true){
        this.opened = true;
      }else{
        this.opened = false;
      }
      
    }
  }

  isBiggerScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      return true;
    } else {
      return false;
    }
  }

  logOut(){
    localStorage.removeItem("tristarAuthSession");
    localStorage.removeItem("tristarUserName");
    console.log('log out');
    window.location.href = '/dashboard';
  }
  
}
