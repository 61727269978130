import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HologramsService } from '../holograms.service';
import { Subscription, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { HotTableRegisterer, HotTableComponent } from '@handsontable/angular';
import { getLocaleDateFormat } from '@angular/common';
import * as Handsontable from 'handsontable';
import { setClassMetadata } from '@angular/core/src/r3_symbols';
import { BoundElementProperty } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EditListService } from "../../holograms/edit-list.service";

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit   {
  private hotRegisterer = new HotTableRegisterer();
  id:string = "holo-table";

  subscription: Subscription;
  dataset: any=[];
  dataLoading:boolean = false;

  tableSettings: any = {
    rowHeaders: true,
    colHeaders: true,
    viewportColumnRenderingOffset: 27,
    viewportRowRenderingOffset: "auto",
    colWidths: 150,
    height: "auto",
     allowInsertColumn: false,
     allowInsertRow: false,
     allowRemoveColumn: false,
     allowRemoveRow: false,
     autoWrapRow: true,
     autoWrapCol: true,
    stretchH: "all",
    width: "auto",
    //maxRows: 22,
    manualRowResize: true,
    manualColumnResize: true,
    hiddenColumns: {
      columns: [0],
      indicators: false
    },
     columns: [
      {
        data: 'ID',
        type: 'text'
        
      },
        {
          data: 'HoloNumber',
          type: 'text',
          title: 'Holo'
          
        },
        {
          data: 'Item',
          type: 'text',
          title: 'Item'
        },
        {
          data: 'Athlete',
          type: 'text',
          title: 'Athlete',
        },
        {
          data: 'Team',
          type: 'text',
          title: 'Team',
        },
        {
          data: 'AuthShow',
          type: 'text',
          title: 'Show',
        },
        {
          data: 'DateAuth',
          type: 'text',
          title: 'Date',
        },
        {
          data: 'CityAuth',
          type: 'text',
          title: 'City',
        },
        {
          data: 'StateAuth',
          type: 'text',
          title: 'State',
        },
        {
          data: 'Inscription',
          type: 'text',
          title: 'Inscr.',
        },
        {
          data: 'Misc',
          type: 'text',
          title: 'Misc.',
        },
        {
          data: 'TspMFG',
          type: 'checkbox',
          title: 'TspMFG',
        }
      ],
    manualRowMove: false,
    manualColumnMove: false,
    contextMenu: false,
    filters: false,
    dropdownMenu: false,
    afterValidate: function(isValid, value, row, prop){
      if(value == false){
        //	console.log( value, row, prop)    
          alert("Invalid")
          //Value = isValid
          // row = inserted invalid value
          //prop = row index changed
      }
			
    }
  };


  selList: any=[];


  constructor(private hs:HologramsService, private router:Router, private snackBar: MatSnackBar, private es: EditListService) { }

  ngOnInit(): void {  
   
    this.es.currentMessage.subscribe(selList => this.selList = selList)
    console.log(this.selList);
    this.loadForm(this.selList);


  }


  loadForm(data):void{
    console.log(data);

    

    setTimeout (() => {
      var mytable = this.hotRegisterer.getInstance(this.id);
      this.dataset = data;
      mytable.loadData( this.dataset);
      this.dataLoading = false;
      
    },500); 
    
  }
  
  saveBulk():void{
    var mytable = this.hotRegisterer.getInstance(this.id);
    var selData:any=[]
    selData =mytable.getData();
   // console.log(selData);

    let UpdatedItems: Array<any> = new Array();

    selData.forEach((item)=>{
      var i:any={};
      i.ID = item[0]
      i.HoloNumber = item[1];
      i.Item = item[2];
      i.Athlete = item[3];
      i.Team = item[4];
      i.AuthShow = item[5];
      i.DateAuth = item[6];
      i.CityAuth = item[7];
      i.StateAuth = item[8];
      i.Inscription = item[9];
      i.Misc = item[10];
      i.TspMFG = item[11];
      UpdatedItems.push(i);
    })

   // console.log(UpdatedItems);
    let q=[];
    
    UpdatedItems.forEach((i)=>{
      q.push(this.hs.updateHolo(i));
    })

    forkJoin(q)
     .subscribe((response) => {
       // console.log(response);
        let snackBarRef = this.snackBar.open('Selected Holograms Updated', '' ,{duration: 1000});
        //this.router.navigateByUrl("/holograms");
     });


  }

  cancel():void{
    this.router.navigateByUrl("/holograms");
  }
 

}

export interface bulkItem {
  HoloNumber: string;
  Item: string;
}
