import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalProvider } from "../globals";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DndUploadService {

  constructor(private http: HttpClient,  public global: GlobalProvider) { }
  msgHeader:HttpHeaders =  new HttpHeaders({'X-Api-Auth-Token':  `${this.global.tristarApiKey}`, 'enctype':'multipart/form-data'});

  addFiles(images: File[], holonumber:string) {
    var arr = []
    var formData = new FormData();
    arr.push(images);

    formData.append('guid', this.newGuid());
    formData.append('hologram', holonumber);


    arr[0].forEach((item, i) => {
      formData.append('file', arr[0][i]);
    })

     return this
          .http.post(`${this.global.apiBaseUrl}` + 'photos/uploadPhotos', formData, {
            headers: this.msgHeader,
            reportProgress:true,
            observe: 'events'
      }).pipe(
        catchError(this.errorMgmt)
      )
  }


  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


}
