import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from "../globals";
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor(private http: HttpClient,  public global: GlobalProvider) { }
  msgHeader:HttpHeaders =  new HttpHeaders({'X-Api-Auth-Token':  `${this.global.tristarApiKey}`, 'Content-Type':'application/json'});

  getPhotos(holoID) {
    return this
          .http.get<Array<photoAPI>>(`${this.global.apiBaseUrl}` + 'photos/' + holoID, {
            headers: this.msgHeader})
  }

  deletePhoto(ID) {
    return this
          .http.delete(`${this.global.apiBaseUrl}` + 'photos/' + ID, {
            headers: this.msgHeader})
  }


}


export interface photoAPI {
  imageID: number;
  HoloNumber: string;
  PhotoPath: string;
  Guid: string;
  extension: string;
}

