import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule , HttpHeaders  } from '@angular/common/http';

import { FormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { SettingsComponent } from './settings/settings.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ReportsComponent } from './reports/reports.component';
import { LoginComponent } from './login/login.component';

import { GlobalProvider } from "./globals";
import { HologramsComponent } from './holograms/holograms.component';
import { ChartModule, ChartComponent } from 'angular2-chartjs';

import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './_shared/confirmation-dialog/confirmation-dialog.component';

import { MatMomentDateModule, MomentDateAdapter } from "@angular/material-moment-adapter";
import { EditorComponent } from './holograms/editor/editor.component';

import { HotTableModule } from '@handsontable/angular';
import { ImageDialogComponent } from './_shared/image-dialog/image-dialog.component';
import { DndUploadDirective } from './_shared/dnd-upload.directive';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    UsersComponent,
    SettingsComponent,
    AlertsComponent,
    ReportsComponent,
    LoginComponent,
    HologramsComponent,
    ConfirmationDialogComponent,
    EditorComponent,
    ImageDialogComponent,
    DndUploadDirective
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    HttpClientModule,
    ChartModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    HotTableModule.forRoot()
    
    

  ],
  entryComponents: [
    ConfirmationDialogComponent,
    ImageDialogComponent
  ],
  providers: [GlobalProvider, ChartComponent, MomentDateAdapter],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
