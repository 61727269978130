import { Component, OnInit, ViewChild } from '@angular/core';
import {ReportsService} from '../reports/reports.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/internal/operators";
import { NgModel } from '@angular/forms';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as Moment from 'moment'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  displayedColumns: string[] = ['SearchDate', 'FirstName', 'LastName', 'IPaddress', 'HoloNumber'];
  dataSource= new MatTableDataSource()
  dataLoading:boolean = true;

  total_count = 0;
  search_count = 0;
  resultsLength = 0;
  
  filter: any = {};
  search: any = {};

  searchUpdated : Subject<NgModel>= new Subject();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(public rs: ReportsService) { }

  ngOnInit(): void {
    this.filter.StartDate = Moment().utc().startOf('day').subtract(29, 'days');
    this.filter.EndDate = Moment().utc().endOf('day');
    this.search.filters = this.filter;

    this.loadSearches()

    this.searchUpdated.pipe(debounceTime(500)).subscribe(value =>{
      this.loadSearches();
     });
  }


  loadSearches(): void{
    this.dataLoading = true;
    if (this.search.pageSize == null){
      this.search.pageSize = 50;
    }

    //console.log(this.search);
    this.rs
    .getSearchLog(this.search)
    .subscribe((data)=>{
     // console.log(data);
      this.dataSource = new MatTableDataSource(data["items"]);
      this.resultsLength = data["search_count_limited"];
      this.search_count = data["search_count"];
      this.total_count = data["total_count"];

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataLoading = false;
    })

  }

  clearFilters(): void{
    //console.log('clear filters');
    this.paginator.pageIndex = 0;
    this.filter = {};
    this.search.filters = {};
    this.loadSearches();
  }

  applyFilter(event: Event) {
    this.paginator.pageIndex = 0;
    this.search.filters = this.filter;
    this.search.page = this.paginator.pageIndex + 1;
    this.searchUpdated.next(this.search)
  }

  //dates

  formatSartDate ():void{
    this.filter.StartDate = Moment(this.filter.StartDate).utc().startOf('day');
    this.applyFilter(null);
  }

  formatEndDate ():void{
    this.filter.EndDate = Moment(this.filter.EndDate).utc().endOf('day');
    this.applyFilter(null);
  }

  selToday():void{

    this.filter.StartDate = Moment().utc().startOf('day');
    this.filter.EndDate = Moment().utc().endOf('day');
    this.applyFilter(null);
  }

  selYesterday():void{
    this.filter.StartDate = Moment().utc().startOf('day').subtract(1, 'days');
    this.filter.EndDate = Moment().utc().endOf('day').subtract(1, 'days');
    this.applyFilter(null);
  }

  sel7Days():void{
    this.filter.StartDate = Moment().utc().startOf('day').subtract(6, 'days');
    this.filter.EndDate = Moment().utc().endOf('day');
    this.applyFilter(null);
  }

  sel30days():void{
    this.filter.StartDate = Moment().utc().startOf('day').subtract(29, 'days');
    this.filter.EndDate = Moment().utc().endOf('day');
    this.applyFilter(null);
  }

  selThisMonth():void{
    this.filter.StartDate = Moment().utc().startOf('month');
    this.filter.EndDate = Moment().utc().endOf('month');
    this.applyFilter(null);
  }

  selLastMonth():void{
    this.filter.StartDate = Moment().utc().subtract(1, 'month').startOf('month');
    this.filter.EndDate = Moment().utc().subtract(1, 'month').endOf('month');
    this.applyFilter(null);
  }

}
