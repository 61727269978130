import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditListService {

  editList: Array<any> = [];

  private messageSource = new BehaviorSubject(this.editList);
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  updateEditList(selList: Array<any>) {
    this.messageSource.next(selList);
  }

}
