import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from "../globals";
import {Login} from '../_models/login';

const headers = new HttpHeaders({'X-Api-Auth-Token':  'apikeytest123', 'content-type':'application/json'});

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private http: HttpClient,  public global: GlobalProvider) { }

  adminAuth(formData) {
    return this
          .http.post<Login[]>(`${this.global.apiBaseUrl}` + 'auth/login', formData, {
            headers: new HttpHeaders({'X-Api-Auth-Token':  'apikeytest123', 'Content-Type':'application/json'})})
  }
  isActive(authSession) {
  return this
        .http.get<Login[]>(`${this.global.apiBaseUrl}` + 'auth/isActive?authToken='+ authSession, {
          headers: new HttpHeaders({'X-Api-Auth-Token':  'apikeytest123', 'Content-Type':'application/json'})})
  }

}
