import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from "../globals";
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HologramsService {

  constructor(private http: HttpClient,  public global: GlobalProvider) { }
  msgHeader:HttpHeaders =  new HttpHeaders({'X-Api-Auth-Token':  `${this.global.tristarApiKey}`, 'Content-Type':'application/json'});

  private bulkEditList = new Subject<Array<any>>();
  
  getHolograms(search) {
    return this
          .http.post<HoloAPI[]>(`${this.global.apiBaseUrl}` + 'holograms/getAll', search, {
            headers: this.msgHeader})
  }

  updateHolo(holo) {
    return this
          .http.post<AuthItems[]>(`${this.global.apiBaseUrl}` + 'holograms/updateHolo',  holo,{
            headers: this.msgHeader})
  }

  newHolo(holo) {
    return this
          .http.post<AuthItems[]>(`${this.global.apiBaseUrl}` + 'holograms/newHolo',  holo,{
            headers: this.msgHeader})
  }

  deleteHolo(ID) {
    return this
          .http.delete<AuthItems[]>(`${this.global.apiBaseUrl}` + 'holograms/deleteHolo?ID=' + ID,{
            headers: this.msgHeader})
  }
  
  getBulkEditList():Observable<Array<any>>{
    return this.bulkEditList.asObservable();
  }

  updateBulkEditList(selList: Array<any>) {
    this.bulkEditList.next(selList);
  }

}


/*---models / interface for the returned hologram--*/
export interface HoloAPI {
  items: AuthItems[];
  total_count: number;
  search_count: number;
  search_count_limited: number;
}

export interface AuthItems {
  ID: string;
  HoloNumber: string;
  Item: string;
  Athlete: string;
  Team: string;
  AuthShow: string;
  DateAuth: string;
  CityAuth: string;
  StateAuth: string;
  Inscription: string;
  Misc: string;
  TspMFG:boolean;
}
