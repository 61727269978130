import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient,  public global: GlobalProvider) { }
  msgHeader:HttpHeaders =  new HttpHeaders({'X-Api-Auth-Token':  `${this.global.tristarApiKey}`, 'Content-Type':'application/json'});

  getUsers() {
    return this
          .http.get<userVM[]>(`${this.global.apiBaseUrl}` + 'users/getUsers', {
            headers: this.msgHeader})
  }

  updateUser(user) {
    return this
          .http.post<userVM[]>(`${this.global.apiBaseUrl}` + 'users/updateUser',  user,{
            headers: this.msgHeader})
  }

  newUser(user) {
    return this
          .http.post<userVM[]>(`${this.global.apiBaseUrl}` + 'users/newUser',  user,{
            headers: this.msgHeader})
  }

  deleteUser(userID) {
    return this
          .http.delete<userVM[]>(`${this.global.apiBaseUrl}` + 'users/deleteUser?adminID=' + userID,{
            headers: this.msgHeader})
  }

}

export interface userVM {
  FullName: string;
  Username: string;
  isActive: boolean;
}

