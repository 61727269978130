import { Component, OnInit } from '@angular/core';
import {AlertsService} from '../alerts/alerts.service'
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  alertSettings: any ={};
  
  constructor(private as: AlertsService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {


    this.as
    .getAlertSettings()
    .subscribe((data)=>{
     // console.log(data);
      this.alertSettings = data;
    })

  }

  

  saveSettings():void{
    this.as
    .saveAlertSettings(this.alertSettings)
    .subscribe((data)=>{
     // console.log(data);
      let snackBarRef = this.snackBar.open('Alert Setting Updated', '' ,{duration: 1000});
      
    })

  }
}
