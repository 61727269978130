import { Injectable } from '@angular/core';

@Injectable()
export class GlobalProvider {

  public apiBaseUrl: string = 'https://api2.tristarauthentic.com/api/';
  public UserName: string;
  public isAuthenticated: boolean = false;
  public tristarApiKey: string = 'apikeytest123';
  public modalOpen: boolean = false;
}

