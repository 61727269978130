import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UsersService} from "../users/users.service"
import { MatSort } from '@angular/material/sort';
import { MatSidenav, MatDrawerToggleResult } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../_shared/confirmation-dialog/confirmation-dialog.component';
import { GlobalProvider } from "../globals";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  editUser: any={};
  isEditing:boolean = false;
  dataLoading:boolean = true;
  showBackdrop:boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('UserDrawer') sidenav: MatSidenav;

  constructor(public us: UsersService, private snackBar: MatSnackBar, public dialog: MatDialog, public global: GlobalProvider) { }

  
  displayedColumns: string[] = ['star','Fullname', 'Username', 'isActive'];
  dataSource = new MatTableDataSource();

  ngOnInit(): void {
    this.loadData();
  }

  loadData():void{
    this.dataLoading = true;
    this.us
    .getUsers()
    .subscribe((data)=>{
      //console.log(data);
      //this.alertSettings = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataLoading = false;
    })
  }

  closeEdit(): void{
    this.isEditing = false;
    this.showBackdrop = false;
    this.sidenav.close();
    this.global.modalOpen = false;
  }

  setEditUser(user): void{
    //edit the user inforamtion
    //console.log(user);
    this.isEditing = true;
    this.showBackdrop = true;
    this.global.modalOpen = true;
    this.sidenav.open();
    this.editUser=  Object.assign({}, user);
  }

  updateUser(): void{
    this.dataLoading = true;
    //console.log(this.editUser);

    if (this.isEditing == true){
      this.us
        .updateUser(this.editUser)
        .subscribe((data)=>{
          //console.log('saved');
          let snackBarRef = this.snackBar.open('User Updated', '' ,{duration: 1000});
          this.loadData();
          this.sidenav.toggle();
          this.dataLoading = false;
          this.showBackdrop = false;
          this.global.modalOpen = false;
      })
    }else{
      this.us
        .newUser(this.editUser)
        .subscribe((data)=>{
          //console.log('saved');
          let snackBarRef = this.snackBar.open('New User Created', '' ,{duration: 1000});
          this.loadData();
          this.sidenav.toggle();
          this.dataLoading = false;
          this.showBackdrop = false;
          this.global.modalOpen = false;
      })
    }
    
  }

  addUser(): void{
    this.showBackdrop = true;
    this.global.modalOpen = true;
    this.isEditing = false; //new user
    this.editUser = {}; //clear out user data if exists
    this.sidenav.open();
  }

  deleteUser(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: "Are you sure you want to delete this admin user?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //yes clicked - delete
        this.dataLoading = false;
        this.us
        .deleteUser(this.editUser.adminID)
        .subscribe((data)=>{
          //console.log('saved');
          let snackBarRef = this.snackBar.open('User Deleted', '' ,{duration: 1000});
          this.loadData();
          this.sidenav.toggle();
          this.dataLoading = false;
          this.showBackdrop = false;
          this.global.modalOpen = false;
      })
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
